import React, { useRef, useEffect } from 'react';

const Star = ({ filled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill={filled ? "#4CAF50" : "none"}
    stroke="#4CAF50"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

const PinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#2196F3">
    <path d="M12 0C7.31 0 3.5 3.81 3.5 8.5c0 5.94 7.77 14.59 8.09 14.95 0.19 0.22 0.53 0.22 0.72 0 0.32-0.36 8.09-9.01 8.09-14.95C20.5 3.81 16.69 0 12 0zm0 13c-2.48 0-4.5-2.02-4.5-4.5S9.52 4 12 4s4.5 2.02 4.5 4.5S14.48 13 12 13z"/>
  </svg>
);

const BusinessModal = ({ isOpen, onClose, businesses, targetBusinessName, searchKeyword, latitude, longitude }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <Star key={index} filled={index < Math.floor(rating)} />
    ));
  };

  const downloadCSV = () => {
    const headers = ["Number", "Business Name", "Proximity", "Reviews", "Rating"];
    const csvContent = [
      headers.join(","),
      ...businesses.map((business, index) => 
        [
          index + 1,
          business.title,
          `${business.distance} miles`,
          business.reviews || Math.floor(Math.random() * 1000),
          business.rating || (Math.random() * 1 + 4).toFixed(1)
        ].join(",")
      )
    ].join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "business_results.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 z-50 overflow-y-auto">
      <div ref={modalRef} className="bg-white rounded-lg w-full max-w-4xl my-16 p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-2xl font-bold text-black mb-2">Results for "{targetBusinessName}"</h2>
        <div className="text-sm text-gray-600 mb-4">
          Latitude: {latitude}, Longitude: {longitude}
        </div>
        <div className="mb-4 flex justify-between items-center">
          <div className="text-sm text-gray-600">
            Search Results: {searchKeyword}
          </div>
          <button
            onClick={downloadCSV}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Download CSV
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-left">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-3 text-sm font-semibold text-black">Number</th>
                <th className="py-2 px-3 text-sm font-semibold text-black">Business Name</th>
                <th className="py-2 px-3 text-sm font-semibold text-black">Proximity</th>
                <th className="py-2 px-3 text-sm font-semibold text-black">Reviews</th>
                <th className="py-2 px-3 text-sm font-semibold text-black">Rating</th>
              </tr>
            </thead>
            <tbody>
              {businesses.map((business, index) => (
                <tr key={index} className={`border-b last:border-b-0 ${business.title === targetBusinessName ? 'bg-green-100' : ''}`}>
                  <td className="py-3 px-3 text-black">{index + 1}</td>
                  <td className="py-3 px-3">
                    <div className="flex items-center">
                      <PinIcon />
                      <span className="ml-2 font-medium text-black">{business.title}</span>
                    </div>
                  </td>
                  <td className="py-3 px-3 text-black">{business.distance} miles</td>
                  <td className="py-3 px-3 text-black">{business.reviews || Math.floor(Math.random() * 1000)}</td>
                  <td className="py-3 px-3">
                    <div className="flex items-center">
                      <span className="text-black mr-2">{business.rating || (Math.random() * 1 + 4).toFixed(1)}</span>
                      <div className="flex">
                        {renderStars(business.rating || (Math.random() * 1 + 4))}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BusinessModal;
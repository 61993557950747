import React, { useEffect, useRef, useState, useCallback } from 'react';
import BusinessModal from './BusinessModal'; // Make sure this path is correct

const MapView = ({ results, targetBusinessName, businessLocation, city, pointDistance, searchKeyword }) => {
  const mapRef = useRef(null);
  const [mapError, setMapError] = useState(null);
  const [map, setMap] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);

  // Hardcoded API key (temporary for debugging)
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;


  const getCircleColor = useCallback((ranking) => {
    if (ranking >= 1 && ranking <= 4) return '#34D399';
    if (ranking >= 5 && ranking <= 8) return '#FBBF24';
    return '#EF4444';
  }, []);

  const isValidCoordinate = (coord) => typeof coord === 'number' && !isNaN(coord) && isFinite(coord);

  const initMap = useCallback(() => {
    if (!mapRef.current || !businessLocation) {
      setMapError('Unable to initialize map: missing ref or business location');
      return;
    }

    if (!isValidCoordinate(businessLocation.latitude) || !isValidCoordinate(businessLocation.longitude)) {
      setMapError('Invalid business location coordinates');
      return;
    }

    try {
      const newMap = new window.google.maps.Map(mapRef.current, {
        zoom: 11,
        center: { lat: businessLocation.latitude, lng: businessLocation.longitude },
      });
      setMap(newMap);

      // Add a custom PNG marker for the center (target business location)
      new window.google.maps.Marker({
        position: { lat: businessLocation.latitude, lng: businessLocation.longitude },
        map: newMap,
        title: `${targetBusinessName} (${city || 'Unknown'})`,
        icon: {
          url: 'https://piquant-malkoha.pikapod.net/api/files/2gbbedis75f1ubn/ovfahh0p4qhzb54/website_favicon_BEj46QpgWu.png?token=',
          scaledSize: new window.google.maps.Size(50, 50),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(25, 25)
        },
        zIndex: 9999
      });

      console.log('Map initialized successfully');
    } catch (error) {
      console.error('Error initializing map:', error);
      setMapError(`Failed to initialize map: ${error.message}`);
    }
  }, [businessLocation, targetBusinessName, city]);

  // UPDATED: Changed title to "Results" and reduced top padding
  const createHoverContent = (businesses) => {
    const topBusinesses = businesses.slice(0, 3);
    const targetBusiness = businesses.find(b => b.title === targetBusinessName);
    let content = `
      <div style="background-color: rgba(255, 255, 255, 0.9); border-radius: 8px; padding: 8px 12px; box-shadow: 0 2px 10px rgba(0,0,0,0.1);">
        <h3 style="font-size: 16px; font-weight: bold; margin: 0 0 8px 0; color: #333;">Results</h3>
        <ul style="list-style-type: none; padding: 0; margin: 0;">
    `;
    
    topBusinesses.forEach((b, i) => {
      const color = getCircleColor(i + 1);
      content += `
        <li style="display: flex; align-items: center; margin-bottom: 4px;">
          <span style="width: 20px; height: 20px; background-color: ${color}; border-radius: 50%; margin-right: 8px; flex-shrink: 0;"></span>
          <span style="color: #333; font-size: 14px;">${i + 1}. ${b.title}</span>
        </li>
      `;
    });

    if (targetBusiness && !topBusinesses.includes(targetBusiness)) {
      const targetIndex = businesses.findIndex(b => b === targetBusiness);
      const color = getCircleColor(targetIndex + 1);
      content += `
        <li style="display: flex; align-items: center; margin-top: 8px; border-top: 1px solid #eee; padding-top: 8px;">
          <span style="width: 20px; height: 20px; background-color: ${color}; border-radius: 50%; margin-right: 8px; flex-shrink: 0;"></span>
          <span style="color: #333; font-size: 14px; font-weight: bold;">${targetIndex + 1}. ${targetBusiness.title} (Target)</span>
        </li>
      `;
    }

    content += `
      </ul>
    </div>
    `;
    return content;
  };

  const addGridPoints = useCallback(() => {
    if (!map || !results || !Array.isArray(results) || results.length === 0) {
      console.log('No valid map or results to display');
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    results.forEach((point, index) => {
      if (!isValidCoordinate(point.lat) || !isValidCoordinate(point.lng)) {
        console.warn(`Invalid coordinates for point ${index}: lat=${point.lat}, lng=${point.lng}`);
        return;
      }

      const position = new window.google.maps.LatLng(point.lat, point.lng);
      bounds.extend(position);
      const color = getCircleColor(point.ranking);

      const circle = new window.google.maps.Circle({
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.35,
        map,
        center: position,
        radius: (pointDistance || 3) * 1609.34 / 2,
        zIndex: 1
      });

      const marker = new window.google.maps.Marker({
        position: position,
        map: map,
        label: {
          text: point.ranking ? point.ranking.toString() : '21+',
          color: 'white',
          fontSize: '12px',
          fontWeight: 'bold',
        },
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 0,
        },
        zIndex: 2
      });

      // Add hover effect
      const hoverInfoWindow = new window.google.maps.InfoWindow({
        content: createHoverContent(point.results),
        zIndex: 1000,
        disableAutoPan: true
      });

      circle.addListener('mouseover', () => {
        hoverInfoWindow.setPosition(position);
        hoverInfoWindow.open(map);
      });

      circle.addListener('mouseout', () => {
        hoverInfoWindow.close();
      });

      // Add click event for modal
      circle.addListener('click', () => {
        setSelectedBusinesses(point.results.map(business => ({
          ...business,
          distance: (Math.random() * 2 + 0.1).toFixed(2) // Mock distance
        })));
        setSelectedPoint(point);
        setIsModalOpen(true);
      });
    });

    map.fitBounds(bounds);
  }, [map, results, getCircleColor, pointDistance, targetBusinessName]);

  useEffect(() => {
    console.log('MapView useEffect triggered');
    console.log('Hardcoded Google Maps API Key:', apiKey ? 'Set' : 'Not set');
    console.log('Target Business Name:', targetBusinessName);
    console.log('Business Location:', businessLocation);
    console.log('City:', city);
    console.log('Point Distance:', pointDistance);
    console.log('Results:', results);

    if (!apiKey) {
      setMapError('Google Maps API key is missing. Please check your environment variables.');
      return;
    }

    if (!businessLocation || !isValidCoordinate(businessLocation.latitude) || !isValidCoordinate(businessLocation.longitude)) {
      setMapError('Invalid business location data');
      return;
    }

    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onerror = () => setMapError("Failed to load Google Maps script");
      script.onload = initMap;
      document.head.appendChild(script);
    } else {
      initMap();
    }
  }, [apiKey, initMap, targetBusinessName, businessLocation, city, pointDistance, results]);

  useEffect(() => {
    if (map && results && results.length > 0) {
      addGridPoints();
    }
  }, [map, results, addGridPoints]);

  if (mapError) {
    return <div className="text-red-500 p-4 bg-red-100 rounded-lg">Error: {mapError}</div>;
  }

  return (
    <>
      <div 
        ref={mapRef} 
        style={{ width: '100%', height: '400px' }} 
        className="rounded-lg overflow-hidden shadow-lg"
        aria-label="Map showing business locations"
      />
      <BusinessModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        businesses={selectedBusinesses}
        targetBusinessName={targetBusinessName}
        searchKeyword={searchKeyword}
        latitude={selectedPoint?.lat}
        longitude={selectedPoint?.lng}
      />
    </>
  );
};

export default MapView;